<template>

  <el-form :model="form" inline>
    <el-row justify="space-between" class="project-manage-form">
      <el-col :span="18">
        <el-form-item label="项目标签">
          <el-input v-model="form.name" @input="fetchData(true)" clearable size="small" placeholder="项目标签"/>
        </el-form-item>
      </el-col>
      <el-form-item>
        <el-button icon="el-icon-plus" :disabled="selectedRow.id !== undefined" size="small" type="primary"
                   @click="addProject('add')">新增项目标签
        </el-button>
      </el-form-item>
    </el-row>
  </el-form>

  <el-row class="project-manage">
    <el-table :data="data" :header-cell-style="{ background: 'var(--el-table-row-hover-background-color)' }"
              v-loading="loading">
      <el-table-column label="项目标签" class-name="project-name-cell" header-align="left" align="left">
        <template #default="{row}">
          <template v-if="selectedRow.id !== undefined && selectedRow.id === row.id">
            <el-input ref="inputRef" type="text" autofocus
                      v-model="selectedRow.name" class="project-name-input" size="small" placeholder="请输入项目标签"/>
          </template>

          <template v-else>
            {{ row.name }}
          </template>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="left" header-align="left" width="160">
        <template #default="{ row, $index }">
          <el-row v-if="selectedRow.id !== undefined && selectedRow.id === row.id" class="project-name-operation">
            <el-button :disabled="!selectedRow.name.length" type="text" class="operate-button" size="small"
                       @click="saveProject($index)">确认
            </el-button>
            <el-button type="text" class="operate-button" size="small" @click="cancelEdit(row)"
                       style="color: #F56C6C!important;">取消
            </el-button>
          </el-row>
          <el-row v-else>
            <el-button :disabled="selectedRow.id !== undefined" type="text" class="operate-button" size="small"
                       @click="addProject('edit', row)">编辑
            </el-button>
            <el-button type="text" class="operate-button" size="small" @click="deleteRow = row;deleteDialogVisible=true"
                       style="color: #F56C6C!important;">删除
            </el-button>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
  </el-row>
  <el-row justify="end">
    <el-pagination v-model:page-size="page.pagesize" v-model:current-page="page.page"
                   :total="page.total" :page-sizes="[5, 10, 20, 40, 60, 80, 100, 200, 300, 400]"
                   @size-change="fetchData"
                   @current-change="fetchData" layout="total, sizes, prev, pager, next" background/>
  </el-row>
  <div class="delete-dialog">
    <el-dialog v-model="deleteDialogVisible" width="450px" top="30vh" @close="deleteRow={}">
      <div class="delete">
        <img src="@/assets/images/business/warning.png" alt="">
        <div class="delete-title">是否确定删除此项目标签？</div>
        <div class="delete-info">删除后此项目标签下的所有网站将取消关联状态</div>
        <div class="mt-20">
          <el-button @click="deleteDialogVisible = false" size="small">取消</el-button>
          <el-button type="danger" size="small" @click="deleteProject">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script setup>
import {ref, onMounted, getCurrentInstance, nextTick} from "vue";
import api from "@/request/api/report";
import {ElMessage} from "element-plus";

let loading = ref(false)
let emit = defineEmits(['updateProjects'])

let props = defineProps({
  addProjectNow: {default: false}
})

console.log(props.addProjectNow)

let selectedRow = ref({
  id: undefined, name: ''
})

let initSelectedRow = () => {
  selectedRow.value = {id: undefined, name: ''}
}

let inputRef = ref()

let deleteDialogVisible = ref(false)
let deleteRow = ref({})

let {proxy} = getCurrentInstance()
let data = ref([])
let page = ref({
  total: 0,
  page: 1,
  pagesize: 5
})

let deleteProject = async () => {
  if (!deleteRow.value.id) return ElMessage.warning('未知项目标签')
  let res = await api.manage.deleteProject(deleteRow.value.id)
  ElMessage.success(res.msg ? res.msg : '删除成功')
  deleteRow.value = {}
  deleteDialogVisible.value = false
  emit('updateProjects', {})
  await fetchData()
}

const form = ref({name: ''})

const fields = [
  {id: 'project_name', label: '项目标签', align: 'left', width: 60},
]

let addProject = async (action, row = {}) => {
  if (action === 'add') {
    selectedRow.value = {id: 0, name: ''}
    data.value = [{id: 0, name: ''}].concat(data.value)
  } else {
    selectedRow.value = {id: row.id, name: row.name}
  }

  await nextTick()
  if (inputRef && inputRef.value && inputRef.value.focus) {
    inputRef.value.focus()
  }


}

let saveProject = async (idx) => {
  let res
  if (selectedRow.value.id) {
    res = await api.manage.editProject(selectedRow.value).catch(() => {
    })
  } else {
    res = await api.manage.addProject(selectedRow.value).catch(() => {
    })
  }

  if (res.msg) ElMessage.success(res.msg)

  emit('updateProjects', {})

  if (res.data) {
    console.log(`替换行号: ${idx}`)
    data.value.splice(idx, 1, {id: res.data.id, name: res.data.name})
  }

  initSelectedRow()
}


const fetchData = async (resetPage = false) => {
  loading.value = true
  initSelectedRow()
  let res = await api.manage.getProjectList({
    name: form.value.name,
    page: resetPage === true ? 1 : page.value.page,
    pagesize: resetPage === true ? 5 : page.value.pagesize
  }).finally(() => {
    loading.value = false
  })

  data.value = res.data && res.data.length > 0 ? res.data : []
  page.value = {
    total: res.data && res.total ? res.total : 0,
    page: resetPage !== true && page.value.page ? page.value.page : 1,
    pagesize: resetPage !== true && page.value.pagesize ? page.value.pagesize : 5,
  }
}

let cancelEdit = row => {
  initSelectedRow()
  if (row.id) return
  data.value.splice(0, 1)
}

onMounted(async () => {
  await fetchData()
  if (props.addProjectNow) addProject('add', {})
})

</script>

<style lang="less" scoped>
.project-manage-form {
  :deep(.el-form-item) {
    margin-bottom: 0 !important;
  }
}

.project-manage {
  :deep(tr) {
    height: 48px;
  }

  :deep(.cell) {
    padding-left: 16px !important;
  }

  :deep(.el-table__cell) {
    padding: 0 16px 0 0 !important;
  }

  :deep(.project-name-cell) {
    padding: 0 16px 0 0 !important;
  }

  :deep(.project-name-input) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

  :deep(.project-name-cell .el-input--small .el-input__inner) {
    height: 100%;
    border: 0;
    background-color: #E6F0FF;
    border-radius: 0;
  }

  :deep(.project-name-operation) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-left: 16px;
    background-color: #E6F0FF;

  }

  .operate-button {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
  }
}

.delete-dialog {
  :deep(.el-dialog__body) {
    padding-top: 5px;
  }

  .delete {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 40px;
      height: 40px;
      margin-bottom: 20px;
    }

    :deep(.el-button+.el-button) {
      margin-left: 16px;
    }
  }
}

.operate-row :deep(.el-button) {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
}
</style>