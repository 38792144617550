import axios from '@/request/http'
import base from '@/request/base'
import QS from 'querystring'

const consumption = {
  /** 获取网站消耗 */
  getSiteSpent(data) {
    return axios.get(`${base.admin_url}/cloud/asset/site_spent`, { params: data })
  },
}

const manage = {
  /** 获取网站列表 */
  getSiteList(data) {
    return axios.get(`${base.admin_url}/cloud/asset/site_list`, { params: data })
  },
  /** 添加网站 */
  addSite(data) {
    return axios.post(`${base.admin_url}/cloud/asset/add_site`, data)
  },
  /** 编辑网站 */
  editSite(data) {
    return axios.post(`${base.admin_url}/cloud/asset/edit_site`, data)
  },
  /** 删除网站 */
  deleteSite(id) {
    return axios.post(`${base.admin_url}/cloud/asset/del_site`, { id })
  },
  /** 获取网站所有的项目标签和关联账户 */
  getSiteData() {
    return axios.post(`${base.admin_url}/cloud/asset/site_data`)
  },
  /** 校验网站关联项目标签 */
  checkAccountSite(data) {
    return axios.get(`${base.admin_url}/cloud/asset/jud_site`, { params: data })
  },
  /** 项目标签列表 */
  getProjectList(data) {
    return axios.get(`${base.admin_url}/cloud/asset/project_list`, {params: data})
  },
  /** 添加项目标签 */
  addProject(data) {
    return axios.post(`${base.admin_url}/cloud/asset/add_project`, data)
  },
  /** 添加项目标签 */
  editProject(data) {
    return axios.post(`${base.admin_url}/cloud/asset/edit_project`, data)
  },
  /** 删除项目标签 */
  deleteProject(id) {
    return axios.post(`${base.admin_url}/cloud/asset/del_project`, { id })
  },
}

export default { consumption, manage }
