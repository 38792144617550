<template>
  <el-row class="website-manage">
    <el-col>
      <el-card :body-style="{ 'padding': '20px 20px 80px 20px', 'min-height': cardHeight + 'px' }">
        <template #header>
          <el-row justify="space-between">
            <span class="SubHead">网站管理</span>
            <el-link type="primary" :underline="false"
              @click="dialogVisible = true; dialogType = 'add'; form.id = null; form.name = ''">添加网站
            </el-link>
          </el-row>
        </template>
        <search-form-content ref="searchFormContentRef" :form-items="items" :fields="fields" :default-page-size="10"
          :fetch-data="fetchData" :empty-show="true" :show-btn="false">
          <template #buttons>
            <el-button type="primary" size="small" class="operate-button" @click="projectDialogVisible = true">编辑项目标签
            </el-button>
          </template>
          <template #operations>
            <el-table-column label="操作" align="left" header-align="left" width="160">
              <template #default="{ row }">
                <el-button type="text" class="operate-button" size="small" @click="editSite(row)">编辑
                </el-button>
                <el-button type="text" class="operate-button" size="small"
                  @click="form.id = row.id; deleteDialogVisible = true" style="color: #F56C6C!important;"
                  v-if="row.type === 1">删除网站
                </el-button>
                <el-button type="text" class="operate-button" size="small"
                  @click="form.id = row.id; hideDialogVisible = true" style="color: #F56C6C!important;" v-else>隐藏网站
                </el-button>
              </template>
            </el-table-column>
          </template>
          <template #empty>
            <el-empty :image-size="300">
              <template #description>
                <span class="website-manage-empty-desc">暂无网站，请先<el-button type="text"
                    @click="projectDialogVisible = true; projectAdd = true">编辑项目标签</el-button>后添加网站</span>
              </template>
              <template #image>
                <img src="@/assets/empty.gif" />
              </template>
            </el-empty>
          </template>
        </search-form-content>
      </el-card>
    </el-col>
  </el-row>
  <el-dialog v-model="dialogVisible" :title="dialogType === 'add' ? '添加网站' : '编辑网站'" width="550px" top="30vh"
    :destroy-on-close="true" :close-on-click-modal="false" custom-class="account-permission-dialog" @close="resetForm">
    <el-form label-position="right" label-width="auto" :model="form" ref="ruleFormRef">
      <el-form-item label="网站链接" prop="site_name"
        :rules="[{ required: true, message: '请输入网站链接' }, { validator: validator.urlValidRuleWithoutScheme },]">
        <el-input v-model="form.site_name" size="small" placeholder="请输入网站链接" clearable />
      </el-form-item>
      <el-form-item label="关联广告账户" prop="account_ids" :rules="[{ required: true, message: '请选择关联的广告账户' }]">
        <account-select v-model="form.account_ids" multiple placeholder="请选择关联的广告账户" style="width: 100%;"
          size="small" />
      </el-form-item>
      <el-form-item label="项目标签" prop="project_name">
        <el-select v-model="form.project_name" placeholder="请选择或新增项目标签" style="width: 100%;" size="small" allow-create
          :default-first-option="true" filterable clearable>
          <el-option v-for="item in items.project_name.options" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取消</el-button>
        <el-button type="primary" size="small" @click="handleSite(false)">确定</el-button>
      </span>
    </template>
  </el-dialog>
  <div class="delete-dialog">
    <el-dialog v-model="deleteDialogVisible" width="450px" top="30vh">
      <div class="delete">
        <img src="@/assets/images/business/warning.png" alt="">
        <div class="delete-title">是否确定删除此网站？</div>
        <div>
          <el-button @click="deleteDialogVisible = false" size="small">取消</el-button>
          <el-button type="danger" size="small" @click="handleDeleteSite">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>

  <div class="delete-dialog">
    <el-dialog v-model="hideDialogVisible" width="450px" top="30vh">
      <div class="delete">
        <img src="@/assets/images/business/warning.png" alt="">
        <div class="delete-title">是否确定隐藏此网站？隐藏后将不在网站数据中显示和导出</div>
        <div>
          <el-button @click="hideDialogVisible = false" size="small">取消</el-button>
          <el-button type="danger" size="small" @click="handleDeleteSite">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>

  <el-dialog v-model="noticeDialogVisible" title="提示" width="550px" top="30vh" :modal="false">
    <div class="delete-title">
      检测到您的广告账户有绑定其他网站,若同一账户绑定多个网站站,网站数据页面不同网站的数据将会有重叠部分,请确认是否继续绑定
    </div>
    <el-table :data="notice" size="small">
      <el-table-column label="账户id" prop="account_id" header-align="center" align="center" />
      <el-table-column label="绑定网站" header-align="center" align="center">
        <template #default="{ row }">
          <div v-for="site in row.site_name" :key="site">
            {{ site }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="noticeDialogVisible = false" size="small">取消</el-button>
        <el-button type="primary" size="small" @click="handleSite(true)">确定</el-button>
      </span>
    </template>
  </el-dialog>
  <div class="project-manager-dialog">
    <el-dialog v-model="projectDialogVisible" width="680px" @close="projectDialogVisible = false; projectAdd = false"
      :destroy-on-close="true" :close-on-click-modal="false" title="编辑项目标签">
      <project-manage :add-project-now="projectAdd" @updateProjects="refreshData"></project-manage>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" size="small" @click="projectDialogVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted, provide, h } from 'vue'
import SearchFormContent from '@/components/base/SearchFormContent'
import AccountSelect from '@/components/base/AccountSelect.vue';
import api from '@/request/api/report'
import ProjectManage from "@/views/report/ProjectManage";
import validator from "@/base/validator";

let projectDialogVisible = ref(false)
const { proxy } = getCurrentInstance()
const searchFormContentRef = ref(null)
const cardHeight = ref(window.innerHeight - 180)
const ruleFormRef = ref(null)
const dialogVisible = ref(false)
const deleteDialogVisible = ref(false)
const hideDialogVisible = ref(false)
const projectAdd = ref(false)

const dialogType = ref('add')
const form = ref({
  id: null,
  site_name: '',
  project_name: '',
  account_ids: [],
})

const items = {
  project_name: {
    type: 'selector',
    label: '项目标签',
    options: [],
    placeholder: '请选择项目标签'
  },
  site_name: { label: '网站链接', placeholder: '请输入网站链接', options: [] },
  account_id: {
    type: 'account-selector',
    label: '关联账户',
    options: [],
    placeholder: '请选择关联账户',
  }
}

const fields = [
  { index: true, label: '序号', align: 'left', width: 40 },
  { id: 'project_name', label: '项目标签', align: 'left', width: 60 },
  { id: 'site_name', label: '网站链接', align: 'left', width: 60 },
  { id: 'account_num', label: '账户个数', align: 'left', width: 40 }
]

const notice = ref([])
const noticeDialogVisible = ref(false)

const fetchData = async (params) => {
  console.log('fetch data')
  let res = await api.manage.getSiteList(params)

  console.log(res)
  return {
    page: {
      total: res && res.total ? res.total : 0,
      page: params.page ? params.page : 1,
      pagesize: params.pagesize ? params.pagesize : 10,
    },
    data: res && res.data && res.data.length > 0 ? res.data : [],
  }
}

/** 打开编辑网站弹窗 */
const editSite = (row) => {
  dialogVisible.value = true
  dialogType.value = 'edit'
  form.value.id = row.id
  let account_ids = []
  row.account_ids.forEach(id => {
    account_ids.push({ account_id: id })
  })
  form.value.account_ids = account_ids
  form.value.project_name = row.project_name
  form.value.site_name = row.site_name
}

const getAccountIds = () => {
  let account_ids = []
  form.value.account_ids.forEach(item => {
    account_ids.push(item['account_id'])
  })
  return JSON.stringify(account_ids)
}

/** 添加或编辑网站 */
const handleSite = async (forceSave) => {
  notice.value = []
  noticeDialogVisible.value = false

  let valid = await ruleFormRef.value.validate()
  if (true !== valid) return

  let res
  if (forceSave !== true) {
    console.log(getAccountIds())
    res = await api.manage.checkAccountSite({ account_id: getAccountIds(), site_id: form.value.id })
    if (res.data && Object.keys(res.data).length > 0) {
      notice.value = Object.values(res.data)
      noticeDialogVisible.value = true
      return
    }
  }

  if (dialogType.value === 'add') {
    res = await api.manage.addSite({
      site_name: form.value.site_name,
      project_name: form.value.project_name,
      account_ids: getAccountIds()
    })
  } else {
    res = await api.manage.editSite({
      id: form.value.id,
      site_name: form.value.site_name,
      project_name: form.value.project_name,
      account_ids: getAccountIds()
    })
  }

  proxy.$message.success(res.msg)
  ruleFormRef.value.resetFields()
  dialogVisible.value = false
  searchFormContentRef.value.refreshData()
}


/** 删除网站 */
const handleDeleteSite = () => {
  api.manage.deleteSite(form.value.id).then(res => {
    proxy.$message.success(res.msg)
    deleteDialogVisible.value = false
    hideDialogVisible.value = false
    searchFormContentRef.value.refreshData()
  })
}

/** 清空弹窗表单 */
const resetForm = () => {
  form.value = {
    id: null,
    site_name: '',
    project_name: '',
    account_ids: [],
  }
  if (!ruleFormRef.value) return
  ruleFormRef.value.resetFields()
}

const accountOptions = ref([])
provide('accountOptions', accountOptions)

const refreshData = (refreshList = true) => {
  api.manage.getSiteData().then(res => {
    items.project_name.options = res.project
    items.account_id.options = res.account
    items.site_name.options = res.site
    accountOptions.value = res.account
  })

  if (searchFormContentRef && searchFormContentRef.value && refreshList !== false) searchFormContentRef.value.refreshData()
}

onMounted(() => {
  refreshData(false)
})
</script>

<style lang="less">
.account-permission-dialog {
  .el-dialog__body {
    padding: 30px 40px 0 40px;
  }
}
</style>
<style lang="less" scoped>
.website-manage {
  :deep(.search-form) {
    width: 100%;
  }

  :deep(.search-form .search-form-buttons) {
    float: right;
    line-height: 40px;
  }

  :deep(.website-manage-empty-desc) {
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    color: #595959;

    .el-button {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
    }
  }

  :deep(.search-form) {
    .el-form-item {
      margin-right: 20px;
    }

    .el-form-item:nth-child(3) {
      margin-right: 60px;
    }
  }

  :deep(.el-table .el-table__cell.is-center:not(:nth-child(5))) {
    text-align: left;
  }

  :deep(.el-table__row .el-table__cell:first-child) {
    padding-left: 40px;
  }

  :deep(tr .cell) {
    padding-left: 0;
  }

  :deep(thead tr th:first-child .cell) {
    padding-left: 40px;
  }

  .operate-button {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
  }

  :deep(.el-row.is-justify-end) {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}

.delete-dialog {
  :deep(.el-dialog__body) {
    padding-top: 5px;
  }

  .delete {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 40px;
      height: 40px;
      margin-bottom: 20px;
    }

    :deep(.el-button+.el-button) {
      margin-left: 16px;
    }
  }
}

.project-manager-dialog {
  :deep(.el-dialog__footer) {
    padding-top: 0 !important;
  }
}
</style>